<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-checkbox
            id="status"
            v-model="filter.status"
            name="status"
            value="1"
            unchecked-value="0"
            switch
            @change="onFilterChanged"
          >
            <strong>Show Active Only?</strong>
          </b-form-checkbox>
          <br>
        </b-col>

        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            Add Marketing Program
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditForm(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-marketing-program-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver
        ref="form"
      >
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="marketing program name"
            vid="marketing_program_name"
            rules="required|max:100"
          >
            <b-form-group>
              <label for="marketing_program_name">Marketing Program Name</label>
              <b-input
                id="marketing_program_name"
                v-model="marketingProgram.marketing_program_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="enter marketing program name"
                autocomplete="off"
                :disabled="(state.busy)"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="date from"
            vid="date_from"
            rules="required|max:100"
          >
            <b-form-group>
              <label for="date_from">Date From</label>
              <b-input
                id="date_from"
                v-model="marketingProgram.date_from"
                type="date"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="date to"
            vid="date_to"
            rules="required|max:100"
          >
            <b-form-group>
              <label for="date_to">Date To</label>
              <b-input
                id="date_to"
                v-model="marketingProgram.date_to"
                type="date"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="target"
            vid="target"
            rules="required|numeric"
          >
            <b-form-group>
              <label for="target">Target</label>
              <b-input
                id="target"
                v-model="marketingProgram.target"
                type="number"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter target"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="marketingProgram.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              :disabled="(state.busy)"
            >
              Is Active?
            </b-form-checkbox>
          </div>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="(state.busy)"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, SupervisorMarketingProgramService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'SupervisorMarketingPrograms',

  middleware: ['auth', 'supervisor'],

  metaInfo () {
    return {
      title: 'Marketing Programs'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      filter: {
        status: 0
      },
      marketingProgram: {
        id: 0,
        active: 0,
        marketing_program_name: '',
        date_from: null,
        date_to: null,
        target: 0
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'target' },
          { key: 'marketing_program_name', sortable: true },
          { key: 'active', label: 'IS ACTIVE?' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Marketing Program' : 'Add Marketing Program'
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SupervisorMarketingProgramService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_status: this.filter.status
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onShowAddForm () {
      this.state.editing = false
      this.marketingProgram.id = 0
      this.marketingProgram.active = 1
      this.marketingProgram.marketing_program_name = null
      this.marketingProgram.date_from = null
      this.marketingProgram.date_to = null
      this.marketingProgram.target = null
      this.$bvModal.show('modal-marketing-program-form')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.marketingProgram.id = current.id
      this.marketingProgram.active = current.active
      this.marketingProgram.marketing_program_name = current.marketing_program_name
      this.marketingProgram.date_from = current.date_from
      this.marketingProgram.date_to = current.date_to
      this.marketingProgram.target = current.target
      this.$bvModal.show('modal-marketing-program-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to create marketing program?'
          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        SupervisorMarketingProgramService.post(this.marketingProgram).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-marketing-program-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        SupervisorMarketingProgramService.put(this.marketingProgram).then(({ data }) => {
          this.$bvModal.hide('modal-marketing-program-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.marketingProgram.id })
            row.active = data.marketingProgram.active
            row.marketing_program_name = data.marketingProgram.marketing_program_name
            row.date_from = data.marketingProgram.date_from
            row.date_to = data.marketingProgram.date_to
            row.target = data.marketingProgram.target
            row.updated_at = data.marketingProgram.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
